import ModalDialog from "components/ModalDialog"
import Redistribution from "components/Redistribution"

import useModalAbsent from "./hooks"

const ModalAbsent = ({ opened, userUUID, refresh, onClose }) => {
  const {
    redistribution: { active, closeRedistribution },
    handleCancel,
    handleConfirm,
  } = useModalAbsent({ userUUID, refresh, onClose })

  return (
    <div className="modal-absent">
      <ModalDialog
        data-test="absent-modal"
        icon="reasing"
        title="Redistribuir leads"
        description="Você deseja redistribuir os leads deste usuário?"
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        opened={opened}
      />
      <Redistribution
        data-test="redistribution"
        active={active}
        userUUID={userUUID}
        onClose={closeRedistribution}
      />
    </div>
  )
}

export default ModalAbsent
