import { useCallback, useEffect, useState, useRef } from "react"
import services from "services"
import auth from "utils/auth"
import { useParameters } from "hooks/useParameters"
import _pagination from "utils/pagination"

const useUsers = () => {
  const {
    group_id: [groupId],
    current_page: [currentPage],
  } = useParameters("group_id", "current_page")
  const [users, setUsers] = useState([])
  const [pagination, setPagination] = useState({})
  const [modal, setModal] = useState({})
  const queries = useRef({})
  const user = auth.getUser()

  const setQueries = (values) => {
    queries.current = { ...queries.current, ...values }
  }

  const activateModal = (userUUID) => {
    setModal({ active: true, userUUID })
  }

  const disableModal = () => setModal({ active: false })

  const handleFetch = useCallback(({ data, pagination }) => {
    setUsers(data)
    setPagination({
      ...pagination,
      total_pages: _pagination.getTotalPages(pagination),
    })
  }, [])

  const fetchUsers = useCallback(
    (props) => {
      setQueries(props)

      services.user
        .team(
          {
            group_id: groupId,
            user_id: user?.user_id,
            page: currentPage,
            ...queries.current,
            ...props,
          },
          { hideLoader: true }
        )
        .then(handleFetch)
    },
    [groupId, user.user_id, currentPage, handleFetch]
  )

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  return {
    users,
    pagination,
    modal: {
      ...modal,
      activate: activateModal,
      disable: disableModal,
    },
    refresh: fetchUsers,
  }
}

export default useUsers
