import Form from "components/Forms/Form"
import Input from "components/Forms/Input"
import Button from "components/Button"

import "./TableHeader.styl"
import useTableHeader from "./hooks"

const TableHeader = ({ onSearch }) => {
  const { values, onChange } = useTableHeader(onSearch)

  return (
    <header className="table-header">
      <Form
        className="table-header__form"
        defaultValues={values}
        manually={true}
      >
        {({ form, setValue }) => (
          <>
            <div className="table-header__search">
              <Input
                form={form}
                name="search"
                icon="search"
                data-test="search"
                placeholder="Buscar por nome"
                onChange={(e) => onChange(e?.target, setValue)}
              />
            </div>
            <Button
              className="table-header__button"
              size="small"
              variant="ghost"
              data-test="add-user"
              link={ROUTES.addUser.path}
            >
              Adicionar usuário
            </Button>
          </>
        )}
      </Form>
    </header>
  )
}

export default TableHeader
