import { useState } from "react"
import services from "services"

const useModalAbsent = ({ userUUID, refresh, onClose }) => {
  const [activeRedistribution, setActiveRedistribution] = useState(false)

  const reload = () => {
    onClose()
    refresh()
  }

  const closeRedistribution = () => {
    setActiveRedistribution(false)
    reload()
  }

  const handleCancel = () => {
    services.user.absent({ absent: true, user_id: userUUID }).then(reload)
  }

  const handleConfirm = () => {
    services.user.absent({ absent: true, user_id: userUUID })
    setActiveRedistribution(true)
  }

  return {
    redistribution: {
      active: activeRedistribution,
      closeRedistribution,
    },
    handleCancel,
    handleConfirm,
  }
}

export default useModalAbsent
