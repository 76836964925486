import { Link } from "gatsby"
import If from "components/If"
import Card from "components/Card"
import Hint from "components/Hint"
import Icon from "components/Icon"
import EmptyState from "components/EmptyState"
import { Table, TableList, TableItem } from "components/Table"

import helpers from "utils/helpers"
import { HEADERS } from "./constants"

import useUsersTable from "./hooks"

import "./UsersTable.styl"

const UsersTable = ({ users, refresh, currentPage, activateModal }) => {
  const { onBlockUser, onUnblockUser, onAbsentUser, onUnabsentUser } =
    useUsersTable({ refresh, activateModal })

  return (
    <div className="users-table">
      <If
        condition={users.length}
        renderIf={
          <Table headers={HEADERS}>
            {users?.map((user, index) => {
              const status = helpers.getStatus(user.active, user.absent)
              const hasUIAccess = !!user?.has_ui_access

              return (
                <TableList key={index}>
                  <TableItem>{user.user_name}</TableItem>
                  <TableItem>{user.user_email}</TableItem>
                  <TableItem>{user.group_name}</TableItem>
                  <TableItem>{user.profile_name}</TableItem>
                  <TableItem className={`users-table__item--${status.value}`}>
                    {status.text}
                  </TableItem>
                  <TableItem className="users-table__item--actions">
                    <If
                      condition={hasUIAccess}
                      renderIf={
                        <div
                          data-test="has-ui-access"
                          className="users-table__hint--disabled users-table__hint--container"
                        >
                          <Icon name="pencil" className="users-table__icon" />
                          <Hint
                            isSideways={true}
                            hasAutoWidth
                            balloon="right-side"
                            className="users-table__hint"
                          >
                            Acesso bloqueado
                          </Hint>
                        </div>
                      }
                      renderElse={
                        <Link
                          data-test="edit-user"
                          className="users-table__hint--container"
                          to={`${ROUTES.editUser.path}?user_id=${user.user_id}&current_page=${currentPage}`}
                        >
                          <Icon name="pencil" className="users-table__icon" />
                          <Hint
                            isSideways={true}
                            hasAutoWidth
                            balloon="right-side"
                            className="users-table__hint"
                          >
                            Editar
                          </Hint>
                        </Link>
                      }
                    />

                    <If
                      condition={status.value === "active"}
                      renderIf={
                        <>
                          <button
                            data-test="toggle-absent"
                            className="users-table__hint--container"
                            onClick={() =>
                              onAbsentUser({ user_id: user.user_id })
                            }
                          >
                            <Icon name="clock" className="users-table__icon" />
                            <Hint
                              isSideways={true}
                              hasAutoWidth
                              balloon="right-side"
                              className="users-table__hint"
                            >
                              Ausente
                            </Hint>
                          </button>
                          <button
                            data-test="toggle-block"
                            className="users-table__hint--container"
                            onClick={() =>
                              onBlockUser({ user_id: user.user_id })
                            }
                          >
                            <Icon name="block" className="users-table__icon" />
                            <Hint
                              isSideways={true}
                              hasAutoWidth
                              balloon="right-side"
                              className="users-table__hint"
                            >
                              Bloquear
                            </Hint>
                          </button>
                        </>
                      }
                      renderElse={
                        <>
                          <button
                            data-test="toggle-absent"
                            disabled={status.value !== "absent"}
                            className="users-table__hint--container"
                            onClick={() =>
                              onUnabsentUser({ user_id: user.user_id })
                            }
                          >
                            <Icon
                              name="clock"
                              className="users-table__icon icon__alternative"
                            />
                            <If condition={status.value === "absent"}>
                              <Hint
                                isSideways={true}
                                hasAutoWidth
                                balloon="right-side"
                                className="users-table__hint hint__bigger"
                              >
                                Desmarcar ausência
                              </Hint>
                            </If>
                          </button>
                          <button
                            data-test="toggle-block"
                            disabled={status.value !== "disabled"}
                            className="users-table__hint--container"
                            onClick={() =>
                              onUnblockUser({ user_id: user.user_id })
                            }
                          >
                            <Icon
                              name="unlock"
                              className="users-table__icon icon__alternative"
                            />
                            <If condition={status.value === "disabled"}>
                              <Hint
                                isSideways={true}
                                hasAutoWidth
                                balloon="right-side"
                                className="users-table__hint"
                              >
                                Desbloquear
                              </Hint>
                            </If>
                          </button>
                        </>
                      }
                    />
                  </TableItem>
                </TableList>
              )
            })}
          </Table>
        }
        renderElse={
          <Card className="users-table__card">
            <EmptyState
              className="users-table__empty-state"
              text="Nenhum usuário encontrado"
            />
          </Card>
        }
      />
    </div>
  )
}

export default UsersTable
