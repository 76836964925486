import Can from "components/Can"
import PageHeader from "components/PageHeader"
import Pagination from "components/Pagination"
import { TabsNavigation } from "components/Tabs"
import TableHeader from "./components/TableHeader"
import UsersTable from "./components/UsersTable"
import ModalAbsent from "./components/ModalAbsent"

import useUsers from "./hooks"

import { TABS } from "../constants"

const Users = () => {
  const { users, pagination, modal, refresh } = useUsers()

  return (
    <Can I="management" the="users" otherwise={{ goTo: ROUTES.home.path }}>
      <PageHeader title="Usuários" />
      <section className="users">
        <TabsNavigation tabs={TABS} />
        <TableHeader onSearch={refresh} />
        <UsersTable
          users={users}
          refresh={refresh}
          currentPage={pagination.current_page}
          activateModal={modal.activate}
        />
        <Pagination
          currentPage={pagination.current_page}
          pages={pagination.total_pages}
          onChange={refresh}
        />
        <ModalAbsent
          opened={modal.active}
          userUUID={modal.userUUID}
          refresh={refresh}
          onClose={modal.disable}
        />
      </section>
    </Can>
  )
}

export default Users
