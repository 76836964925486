import Card from "components/Card"
import Html from "components/Html"
import Icon from "components/Icon"
import If from "components/If"
import Text from "components/Typography/Text"
import Title from "components/Typography/Title"
import classnames from "classnames"

import useModalDialog from "./hooks"

import "./ModalDialog.styl"

const ModalDialog = ({
  className = "",
  icon,
  title,
  description,
  opened = false,
  customConfirm,
  onCancel,
  onConfirm,
  ...props
}) => {
  const { active, cancel, confirm } = useModalDialog(
    opened,
    onCancel,
    onConfirm
  )

  return (
    <If condition={active}>
      <div className={classnames("modal-dialog", className)} {...props}>
        <Card className="modal-dialog__card" spacing="none" radius="medium">
          <If condition={icon}>
            <Icon name={icon} />
          </If>
          <If condition={title}>
            <Title className="modal-dialog__title" variant="small">
              <Html>{title}</Html>
            </Title>
          </If>
          <Text className="modal-dialog__description">
            <Html>{description}</Html>
          </Text>
          <If
            condition={customConfirm}
            renderIf={
              <button
                className="modal-dialog__button modal-dialog__button--custom"
                onClick={confirm}
              >
                {customConfirm}
              </button>
            }
            renderElse={
              <div className="modal-dialog__actions">
                <button className="modal-dialog__button" onClick={cancel}>
                  Não
                </button>
                <button
                  className="modal-dialog__button modal-dialog__button--confirm"
                  onClick={confirm}
                >
                  Sim
                </button>
              </div>
            }
          />
        </Card>
      </div>
    </If>
  )
}

export default ModalDialog
