export const TABS = [
  {
    name: "Empresas",
    path: ROUTES.companies.path,
    permission: {
      subject: "management",
      action: "companies",
    },
  },
  {
    name: "Usuários",
    path: ROUTES.users.path,
    permission: {
      subject: "management",
      action: "users",
    },
  },
]
