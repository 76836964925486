import { useState } from "react"

import debounce from "utils/debounce"
import helpers from "utils/helpers"
import form from "./form"

const useTableHeader = (onSearch) => {
  const [values] = useState(form.values)

  const onChange = debounce((target, setFieldValue) => {
    const value = target.value

    setFieldValue("search", value)

    const data = helpers.isSearchable(value)
      ? { user_name: value, page: "1" }
      : { user_name: "" }

    onSearch(data)
  }, 1000)

  return { values, onChange }
}

export default useTableHeader
