const createMarkup = (data) => ({
  __html: data,
})

const Html = ({ children, ...props }) => (
  <span
    className="content"
    {...props}
    dangerouslySetInnerHTML={createMarkup(children)}
  />
)

export default Html
