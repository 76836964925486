import { useEffect, useState } from "react"
import scroll from "utils/scroll"

const useModalDialog = (opened, onCancel, onConfirm) => {
  const [active, setActive] = useState(false)

  const show = () => {
    setActive(true)
    scroll.body.disabled()
  }

  const hide = () => {
    setActive(false)
    scroll.body.enable()
  }

  const cancel = () => {
    hide()
    onCancel()
  }

  const confirm = () => {
    hide()
    onConfirm()
  }

  useEffect(() => {
    if (opened) show()
  }, [opened])

  return {
    active,
    cancel,
    confirm,
  }
}

export default useModalDialog
