import services from "services"

const useUsersTable = ({ refresh, activateModal }) => {
  const onBlockUser = ({ user_id }) => {
    services.user.block({ user_id }).then(refresh)
  }

  const onUnblockUser = ({ user_id }) => {
    services.user.unblock({ user_id }).then(refresh)
  }

  const onAbsentUser = ({ user_id }) => {
    activateModal(user_id)
  }

  const onUnabsentUser = ({ user_id }) => {
    services.user.absent({ absent: false, user_id }).then(refresh)
  }

  return {
    onBlockUser,
    onUnblockUser,
    onAbsentUser,
    onUnabsentUser,
  }
}

export default useUsersTable
